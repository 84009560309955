<template>
  <a-modal
    :title="getTitle"
    :visible="visible"
    width="38%"
    :centered="true"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="flex items-center m-b-sm">
      <span class="flex-s title m-r-xs text-right">策略名称：</span>
      <a-input
        v-model="strategy"
        class="flex-one m-r-sm"
        placeholder="请输入策略名称"
        :maxLength="30"
      />
      <span class="text-red">30个字</span>
    </div>
    <div class="flex m-b-sm">
      <span class="flex-s title m-r-xs text-right">检测内容：</span>
      <a-checkbox-group
        v-model="checkedValues"
        @change="onCheckChange"
        class="flex-one"
      >
        <a-row>
          <a-col :span="8" v-for="item in examContent" :key="item.id">
            <a-checkbox :value="item.id">
              {{ item.text }}
            </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </div>
    <div class="flex items-center m-b-sm">
      <span class="flex-s title m-r-xs text-right">抽检时间：</span>
      <a-radio-group name="radioGroup" v-model="checkTime">
        <a-radio value="0">
          立刻执行
        </a-radio>
        <a-radio value="1">
          定时执行
        </a-radio>
      </a-radio-group>
    </div>
    <div class="flex  m-b-sm" v-show="checkTime === '1'">
      <span class="flex-s title m-r-xs text-right line-ht">执行时间：</span>
      <div class="flex-one">
        <div class="flex-one flex items-center">
          <a-date-picker
            class="m-r-xs"
            v-model="datePicker"
            :allowClear="false"
            @change="onDateChange"
          />
          <a-select v-model="turnDateNewHour" class="m-r-xs wd">
            <a-select-option
              v-for="item in hourArr"
              :key="item.value"
              :value="item.value"
              :disabled="item.disabled"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
          <span class="m-r-xs m-l-xs">时</span>
          <a-select v-model="turnDateNewMinute" class="wd">
            <a-select-option
              v-for="item in minuteArr"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
          <span class="m-l-xs m-r-xs">分</span>
        </div>
        <span>本次检测将在 {{ getTodayDate }}自动执行</span>
      </div>
    </div>
    <div class="flex  m-b-sm">
      <span class="flex-s title m-r-xs text-right line-ht">抽检内容：</span>
      <span class="text-lightblue flex items-center">
        <a-icon type="plus-circle" class="m-r-sm" @click="addHandle" />已选
        <span class="text-line m-l-xs m-r-xs">{{
          selectCameraList ? selectCameraList.length : 0
        }}</span>
        路摄像机</span
      >
    </div>
    <div class="flex items-center m-b-sm" v-show="false">
      <span class="flex-s title m-r-xs text-right">清晰度：</span>
      <a-radio-group name="radioGroup" v-model="clear">
        <a-radio value="0">
          标清
        </a-radio>
        <a-radio value="1">
          高清
        </a-radio>
      </a-radio-group>
    </div>
    <div class="flex m-b-sm">
      <span class="flex-s title m-r-xs text-right">策略描述：</span>
      <div class="flex-one">
        <a-textarea
          placeholder="请输入策略描述"
          v-model="strategyDes"
          :maxLength="200"
        />
        <div class="text-right text-red">200个字</div>
      </div>
    </div>
    <cameral-select
      :visible="cameraSelectVisible"
      :resource="resource"
      @closeCameralDialog="closeCameralDialogHandle"
    />
  </a-modal>
</template>

<script>
import CameralSelect from '@/components/CameralSelect';
import { getTimes } from '@/tools/';
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'EditDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    currentRecord: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      confirmLoading: false,
      strategy: '',
      checkedValues: [],
      checkTime: '0',
      datePicker: null,
      turnDateNewHour: '00', //'08',
      turnDateNewMinute: '00', //'40',
      hourArr: (() => {
        return getTimes(23);
      })(),
      minuteArr: (() => {
        return getTimes(59);
      })(),
      cameraSelectVisible: false,
      resource: false,
      clear: '0',
      strategyDes: '',
      exceptCheckReset: [],
      examContent: [
        {
          id: 0,
          text: '网络检测',
          check: true,
        },
        {
          id: 1,
          text: '黑屏检测',
          check: true,
        },
        {
          id: 2,
          text: '遮挡检测',
          check: true,
        },
        {
          id: 3,
          text: '模糊检测',
          check: true,
        },
        {
          id: 4,
          text: '亮度检测',
          check: true,
        },
        {
          id: 5,
          text: '静帧检测',
          check: true,
        },
        {
          id: 6,
          text: '噪声检测',
          check: true,
        },
        {
          id: 7,
          text: '闪烁检测',
          check: true,
        },
        {
          id: 8,
          text: '滚动条纹检测',
          check: true,
        },
      ],
      /* disabledDate: (currentDate) => {
        return currentDate < moment().subtract(1, 'day');
      }, */
    };
  },
  computed: {
    ...mapState({
      selectCameraList: (state) => state.collect.selectCameraList,
    }),
    getTitle() {
      return `${this.isEdit ? '编辑' : '新增'}视频抽检`;
    },
    getTodayDate() {
      return this.datePicker
        ? `${moment(this.datePicker).format('YYYY-MM-DD')} ${
            this.turnDateNewHour
          }:${this.turnDateNewMinute}:00`
        : '-- 时间 ';
    },
  },
  watch: {
    isEdit(nv, ov) {
      if (nv && nv !== ov) {
        this.initData();
      }
    },
  },
  created() {
    this.initCheck();
  },
  methods: {
    ...mapMutations(['setCurrentComIndex', 'setSelectCameraList']),
    resetData() {
      this.strategy = '';
      this.checkedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      this.checkTime = '0';
      this.datePicker = null;
      this.turnDateNewHour = '00'; //'08',
      this.turnDateNewMinute = '00';
      this.clear = '0';
      this.strategyDes = '';
      this.setSelectCameraList([]);
    },
    initData() {
      this.strategy = this.currentRecord.detectionName;
      this.clear = this.currentRecord.definition;
      this.strategyDes = this.currentRecord.detectionInfo;
      this.checkTime = this.currentRecord.detectionTimeType;
      let detectionContentArr = this.currentRecord.detectionContent;
      this.setExceptCheck(detectionContentArr);
      /* let checkedValues = [];
      detectionContentArr.forEach((item, index) => {
        this.exceptCheckReset[index] = item;
        if (item === '1') {
          checkedValues[index] = index;
        }
      }); 
      this.checkedValues = checkedValues;*/
      this.datePicker = moment(this.currentRecord.detectionTime);
      let time = this.currentRecord.detectionTime.split(' ');
      this.turnDateNewHour = time[1].split(':')[0];
      this.turnDateNewMinute = time[1].split(':')[1];
      this.setSelectCameraLists(this.currentRecord);
      // this.setSelectCameraList(this.currentRecord.cameraRespList);
    },
    setSelectCameraLists(record) {
      let data = {
        cameraPatrolPlanId: record.id,
      };
      this.$api.home.getCamerasByDetectionId(data).then((res) => {
        if (res.code === 200) {
          this.setSelectCameraList(res.data.list);
        } else {
          this.setSelectCameraList([]);
          this.$message.error('拉取抽检关联摄像机列表有误！');
        }
      });
    },
    setExceptCheck(except) {
      let exceptArr = except.split('');
      let ids = [];
      exceptArr.forEach((item, index) => {
        if (item == '1') {
          ids.push(index);
        }
      });
      this.checkedValues = ids;
    },
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
    handleOk() {
      let obj = {
        cameraNumList: this.selectCameraList
          ? this.selectCameraList.map((item) => item.cameraNum)
          : [],
        // definition: this.clear,
        detectionContent: this.exceptCheckReset.join(''),
        detectionInfo: this.strategyDes.trim(),
        detectionName: this.strategy.trim(),
        detectionTime:
          this.checkTime === '1' && this.datePicker
            ? `${moment(this.datePicker).format('YYYY-MM-DD')} ${
                this.turnDateNewHour
              }:${this.turnDateNewMinute}:00`
            : '',
        detectionTimeType: this.checkTime,
      };
      if (!obj.detectionName) {
        this.$message.warn('策略名称不能为空！');
        return;
      }
      if (obj.detectionTime && moment(obj.detectionTime) < +new Date()) {
        this.$message.error('定时执行时间应选择当前时间之后！');
        return;
      }
      if (obj.cameraNumList.length <= 0) {
        this.$message.error('请选择摄像机');
        return;
      }
      if (!this.isEdit) {
        this.$api.collect.addCameraDetection(obj).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('新增视频抽检成功');
            this.resetData();
            this.$emit('sure');
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        obj.id = this.currentRecord.id;
        this.$api.collect.addCameraDetection(obj).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('修改视频抽检成功');
            this.resetData();
            this.$emit('sure');
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    initCheck() {
      this.checkedValues = this.examContent.map((item) => item.id);
      this.exceptCheckReset = new Array(this.checkedValues.length).fill('1');
    },
    onCheckChange(checkedValues) {
      let newArr = new Array(9).fill(-1);
      this.exceptCheckReset = [];
      newArr.forEach((item, index) => {
        this.exceptCheckReset[index] = 0;
        checkedValues.forEach((cv) => {
          if (index === cv) {
            this.exceptCheckReset[index] = 1;
          }
        });
      });
      this.checkedValues = checkedValues;
    },
    onDateChange(value) {
      this.datePicker = value;
    },
    addHandle() {
      this.cameraSelectVisible = true;
      //设置默认数据
      this.setCurrentComIndex(0);
    },
    closeCameralDialogHandle() {
      this.cameraSelectVisible = false;
    },
  },
  components: {
    CameralSelect,
  },
};
</script>

<style lang="less" scoped>
.title {
  flex: 0 0 90px;
  &.line-ht {
    line-height: 32px;
  }
}
.wd {
  width: 22%;
}
</style>
