<template>
  <div class="full-width full-height bg-blue p-sm relative">
    <back class="m-b-sm" />
    <!-- <ul class="tab-list flex items-center m-b-md">
      <li class="text-lightblue current">
        视频抽检
      </li>
    </ul> -->
    <div class="flex items-center justify-between m-b-sm">
      <div class="flex items-center flex-one text-lightblue">
        <span class="flex-s m-r-xs">状态：</span>
        <a-select
          placeholder="请选择状态"
          class="search-mx-wd-1 m-r-sm"
          v-model="currentStatus"
          @change="changeStatus"
        >
          <a-select-option
            v-for="item in statusList"
            :key="item.id"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
        <span class="flex-s m-r-xs">创建人：</span>
        <a-select
          class="search-mx-wd-1 m-r-sm"
          placeholder="请选择用户"
          v-model="currentUser"
        >
          <a-select-option v-for="item in userList" :key="item.userId">
            {{ item.loginName }}
          </a-select-option>
        </a-select>
        <span class="flex-s m-r-xs">策略名称：</span>
        <a-input
          v-model="strategy"
          class="search-mx-wd-1 m-r-sm"
          placeholder="请输入策略名称"
        />
        <span class="flex-s m-r-xs">创建时间：</span>
        <a-range-picker
          v-model="dateRange"
          format="YYYY-MM-DD HH:mm:ss"
          class="m-r-sm search-date-picker"
          showTime
          @change="changeDataRange"
        />
        <a-button
          type="primary"
          class="m-r-sm btn-search"
          @click="searchHandle"
        >
          搜索
        </a-button>
        <a-button class="btn-reset m-r-sm" @click="resetHandle">
          重置
        </a-button>
      </div>
      <div class="flex">
        <span class="span-line m-l-md m-r-md"></span>
        <a-button  class="btn-export" @click="checkHandle"
          >新建抽检</a-button
        >
      </div>
    </div>

    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :components="resibleTableHeader"
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :data-source="cameraInfoList"
      :pagination="paginationOpt"
      :scroll="scroll"
      :loading="loading"
      bordered
    >
      <template slot="customOpe" slot-scope="text, record">
        <a-tooltip placement="top">
          <template slot="title">
            <span>修改</span>
          </template>
          <a-icon
            type="edit"
            class="m-r-sm pointer"
            :class="{ 'not-cursor icon-disabled': getEditDisabled(record) }"
            @click="editHandle(record)"
          />
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>详情</span>
          </template>
          <a-icon
            type="profile"
            class="m-r-sm pointer"
            :class="{ 'not-cursor icon-disabled': getViewDisabled(record) }"
            @click="viewHandle(record)"
          />
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>删除</span>
          </template>
          <a-icon type="rest" class="pointer" @click="delHandle(record)" />
        </a-tooltip>
      </template>
    </a-table>

    <div class="detach p-w-sm absolute">
      <a-checkbox
        class="m-r-sm m-l-sm"
        :disabled="getCheckDisabled"
        :indeterminate="indeterminate"
        v-model="checkAll"
        @change="checkAllHandle"
      />
      <a-button type="primary" :disabled="getDisabled" @click="detachDel"
        >批量删除</a-button
      >
    </div>

    <edit-dialog
      :visible="editVisible"
      :isEdit="isEdit"
      :currentRecord="currentRecord"
      @close="closeEditDialogHandle"
      @sure="sureHandle"
    />
    <detail-dialog
      :visible="detailVisible"
      :currentTaskId="currentTaskId"
      @close="closeDetailHandle"
    />
  </div>
</template>

<script>
import EditDialog from './components/EditDialog';
import DetailDialog from './components/DetailDialog';
import { CODE_OK } from '@/request/config_code';
import moment from 'moment';
import Back from '@/components/Back';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'SpotCheck',
  mixins:[ResizableTable],
  data() {
    return {
      indeterminate: false,
      currentStatus: '',
      statusList: [
        {
          id: 0,
          text: '全部',
          value: '',
        },
        {
          id: 1,
          text: '等待执行',
          value: 0,
        },
        {
          id: 2,
          text: '检测中',
          value: 1,
        },
        {
          id: 3,
          text: '执行失败',
          value: -1,
        },
        {
          id: 4,
          text: '执行成功',
          value: 2,
        },
      ],
      currentUser: undefined,
      strategy: '',
      dateRange: null,
      selectedRowKeys: [],
      checkAll: false,
      editVisible: false,
      isEdit: false,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: total => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.resetSelectedRows();
          this._getDetectionList();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.resetSelectedRows();
          this._getDetectionList();
        },
      },
      columns: [
        {
          title: '序号',
          dataIndex:'detectionIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '策略名称',
          dataIndex: 'detectionName',
          key: 'detectionName',
          width: 180,
          ellipsis: true,
        },
        {
          title: '检测内容',
          dataIndex: 'detectionContent',
          key: 'detectionContent',
          width: 200,
          ellipsis: true,
          customRender: (text, record) => {
            return this.getExceptionContent(record.detectionContent);
          },
        },
        {
          title: '摄像机数量',
          dataIndex: 'cameraNumber',
          key: 'cameraNumber',
          width: 120,
          ellipsis: true,
        },
        /* {
          title: '清晰度',
          dataIndex: 'definition',
          key: 'definition',
          ellipsis: true,
          customRender: (text) => {
            return text === '1' ? '高清' : '低清';
          },
        }, */
        {
          title: '策略描述',
          dataIndex: 'detectionInfo',
          key: 'detectionInfo',
          ellipsis: true,
          width: 160,
        },
        {
          title: '抽检时间',
          dataIndex: 'detectionTime',
          key: 'detectionTime',
          width: 120,
          ellipsis: true,
        },
        {
          title: '创建人',
          dataIndex: 'userName',
          key: 'userName',
          width: 100,
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          width: 120,
          ellipsis: true,
        },
        {
          title: '当前状态',
          dataIndex: 'detectionStatus',
          key: 'detectionStatus',
          width: 90,
          ellipsis: true,
          customRender: text => {
            return text === '0'
              ? '等待执行'
              : text === '1'
              ? '检测中'
              : text === '2'
              ? '执行成功'
              : '执行失败';
          },
        },
        {
          title: '操作',
          dataIndex: 'ope',
          key: 'ope',
          ellipsis: true,
          width:100,
          className:'column-center',
          scopedSlots: { customRender: 'customOpe' },
        },
      ],
      cameraInfoList: [],
      userList: [],
      currentRecord: {},
      detailVisible: false,
      currentTaskId: '',
      scroll: { y: window.innerHeight - 340 ,x:'100%'},
      loading: false,
    };
  },
  computed: {
    getDisabled() {
      return !this.selectedRowKeys.length;
    },
    getCheckDisabled() {
      return !this.cameraInfoList.length;
    },
  },
  components: {
    EditDialog,
    Back,
    DetailDialog,
  },
  watch: {},
  created() {
    this._getUserList();
    this._getDetectionList();
  },
  methods: {
    getExceptionContent(detectionContent) {
      let dcArr = detectionContent.split('');
      let strArr = [
        '网络检测',
        '黑屏检测',
        '遮挡检测',
        '模糊检测',
        '亮度检测',
        '静帧检测',
        '噪声检测',
        '闪烁检测',
        '滚动条纹检测',
      ];
      let formatArr = [];
      dcArr.forEach((item, index) => {
        if (item === '1') {
          formatArr.push(strArr[index]);
        }
      });
      return formatArr.join(',');
    },
    getEditDisabled(record) {
      return record.detectionStatus !== '0';
    },
    getViewDisabled(record) {
      return record.detectionStatus !== '2';
    },
    _getDetectionList() {
      let params = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        userId: this.currentUser,
        detectionName: this.strategy,
        startTime: this.dateRange
          ? moment(this.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endTime: this.dateRange
          ? moment(this.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        detectionStatus: this.currentStatus,
      };
      this.loading = true;
      this.$api.collect.getDetectionList(params).then(res => {
        if (res.code === CODE_OK) {
          this.cameraInfoList = res.data;
          this.paginationOpt.total = res.total;
        } else {
          this.$message.error('获取抽检信息出错！');
        }
        this.loading = false;
      });
    },
    _getUserList() {
      this.$api.cameraGroup.getUserList().then(res => {
        if (res.code === CODE_OK) {
          this.userList = res.data;
        }
      });
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getDetectionList();
    },
    resetHandle() {
      this.currentUser = undefined;
      this.strategy = '';
      this.dateRange = null;
      this.currentStatus = '';
      this.searchHandle();
    },
    changeStatus(value) {
      this.currentStatus = value;
    },
    changeDataRange(value) {
      this.dateRange = value;
    },
    checkHandle() {
      if (!this.hasPermission(10921410)) {
        this.permissionWarning();
        return;
      }

      this.editVisible = true;
      this.isEdit = false;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length === this.cameraInfoList.length) {
        this.checkAll = true;
        this.indeterminate = false;
      } else if (
        this.selectedRowKeys.length !== 0 &&
        this.selectedRowKeys.length < this.cameraInfoList.length
      ) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.checkAll = false;
        this.indeterminate = false;
      }
    },
    checkAllHandle(e) {
      this.checkAll = e.target.checked;
      this.indeterminate = false;
      this.selectedRowKeys = this.checkAll
        ? this.cameraInfoList.map(item => item.id)
        : [];
    },
    detachDel() {
      if (!this.hasPermission(10921413)) {
        this.permissionWarning();
        return;
      }

      this.$confirm({
        title: '提示',
        content: '该操作无法撤销，确定批量删除所选抽检策略？',
        onOk: () => {
          let data = this.selectedRowKeys;
          this.$api.collect.removeCameraDetection(data).then(res => {
            if (res.code === CODE_OK) {
              this.$message.success('删除该条抽检策略成功！');
              this.searchHandle();
              this.resetSelectedRows();
            } else {
              this.$message.success('删除该条抽检策略出错！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    resetSelectedRows() {
      this.selectedRowKeys = [];
    },
    editHandle(records) {
      if (!this.hasPermission(10921411)) {
        this.permissionWarning();
        return;
      }

      // console.log(records);
      if (this.getEditDisabled(records)) {
        return;
      }
      this.editVisible = true;
      this.isEdit = true;
      this.currentRecord = records;
    },
    closeEditDialogHandle() {
      this.editVisible = false;
      this.isEdit = false;
      this.currentRecord = {};
    },
    sureHandle() {
      this.closeEditDialogHandle();
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.resetSelectedRows();
      this._getDetectionList();
    },
    delHandle(record) {
      if (!this.hasPermission(10921412)) {
        this.permissionWarning();
        return;
      }

      this.$confirm({
        title: '提示',
        content: '该操作无法撤销，确定删除当前抽检策略？',
        onOk: () => {
          let data = [record.id];
          this.$api.collect.removeCameraDetection(data).then(res => {
            if (res.code === CODE_OK) {
              this.$message.success('删除该条抽检策略成功！');
              this.searchHandle();
            } else {
              this.$message.success('删除该条抽检策略出错！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    viewHandle(record) {
      if (this.getViewDisabled(record)) {
        return;
      }
      this.detailVisible = true;
      this.currentTaskId = record.id;
    },
    closeDetailHandle() {
      this.currentTaskId = '';
      this.detailVisible = false;
    },
  },
};
</script>

<style scoped>
.tab-item {
  margin: 0 10px;
  line-height: 32px;
}
.current {
  border-bottom: 2px solid #86b3e9;
}
.wd120 {
  width: 120px;
}
.wd140 {
  width: 140px;
}
.detach {
  transform: translateY(-50px);
}
</style>
