<template>
  <a-modal
    title="抽检结果统计"
    :visible="visible"
    width="80%"
    :centered="true"
    :footer="null"
    @cancel="handleCancel"
  >
    <div class="flex items-center text-lightblue m-b-sm">
      <span class="flex-s m-r-xs">异常类型：</span>
      <a-select
        placeholder="请选择状态"
        class="search-mx-wd-1 m-r-sm"
        v-model="currentStatus"
      >
        <a-select-option
          v-for="item in statusList"
          :key="item.id"
          :value="item.value"
        >
          {{ item.text }}
        </a-select-option>
      </a-select>

      <span class="flex-s m-r-xs">摄像机名称：</span>
      <a-input
        v-model="cameraName"
        class="search-mx-wd-1 m-r-sm"
        placeholder="请输入摄像机名称"
      />
      <span class="flex-s m-r-xs">检测时间：</span>
      <a-range-picker
        v-model="datePicker"
        format="YYYY-MM-DD HH:mm:ss"
        class="search-date-picker  m-r-sm"
        showTime
        @change="changeDataRange"
      />
      <a-button type="primary" class="m-r-sm btn-search" @click="searchHandle">
        搜索
      </a-button>
      <a-button class="btn-reset" @click="resetHandle">
        重置
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => index"
      :components="resibleTableHeader"
      :data-source="cameraInfoList"
      :pagination="paginationOpt"
      :loading="loading"
      bordered
      :scroll="scroll"
    >
    </a-table>
  </a-modal>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
import moment from 'moment';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'DetailDialog',
  mixins:[ResizableTable],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentTaskId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.getDetail();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.getDetail();
        },
      },
      cameraInfoList: [],
      columns: [
        {
          title: '排序',
          dataIndex: 'cameraIndex',
          customRender: (text, record, index) => index + 1,
          width: 50,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          ellipsis: true,
          width:140
        },
        {
          title: '抽检时间',
          dataIndex: 'detectionTime',
          key: 'detectionTime',
          width: 120,
          ellipsis: true,
        },
        {
          title: '网络检测',
          dataIndex: 'k_0',
          key: 'k_0',
          width: 80,
          ellipsis: true,
          className: 'column-center',
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '黑屏检测',
          dataIndex: 'k_1',
          key: 'k_1',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '遮挡检测',
          dataIndex: 'k_2',
          key: 'k_2',
          className: 'column-center',
          ellipsis: true,
          width: 80,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '模糊检测',
          dataIndex: 'k_3',
          key: 'k_3',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '亮度检测',
          dataIndex: 'k_4',
          key: 'k_4',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '静帧检测',
          dataIndex: 'k_5',
          key: 'k_5',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '噪声检测',
          dataIndex: 'k_6',
          key: 'k_6',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '闪烁检测',
          dataIndex: 'k_7',
          key: 'k_7',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '滚动条纹检测',
          dataIndex: 'k_8',
          key: 'k_8',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
      ],
      tempColumns: [
        {
          title: '排序',
          dataIndex: 'cameraIndex',
          customRender: (text, record, index) => index + 1,
          width: 50,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          ellipsis: true,
          width:140
        },
        {
          title: '抽检时间',
          dataIndex: 'detectionTime',
          key: 'detectionTime',
          width: 120,
          ellipsis: true,
        },
        {
          title: '网络检测',
          dataIndex: 'k_0',
          key: 'k_0',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '黑屏检测',
          dataIndex: 'k_1',
          key: 'k_1',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '遮挡检测',
          dataIndex: 'k_2',
          key: 'k_2',
          className: 'column-center',
          ellipsis: true,
          width: 80,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '模糊检测',
          dataIndex: 'k_3',
          key: 'k_3',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '亮度检测',
          dataIndex: 'k_4',
          key: 'k_4',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '静帧检测',
          dataIndex: 'k_5',
          key: 'k_5',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '噪声检测',
          dataIndex: 'k_6',
          key: 'k_6',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '闪烁检测',
          dataIndex: 'k_7',
          key: 'k_7',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
        {
          title: '滚动条纹检测',
          dataIndex: 'k_8',
          key: 'k_8',
          className: 'column-center',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.getIcon(text);
          },
        },
      ],
      cameraName: '',
      currentStatus: '',
      datePicker: null,
      statusList: [
        {
          id: 0,
          text: '全部',
          value: '',
        },
        {
          id: 1,
          text: '网络检测',
          value: 1,
        },
        {
          id: 2,
          text: '黑屏检测',
          value: 2,
        },
        {
          id: 3,
          text: '遮挡检测',
          value: 3,
        },
        {
          id: 4,
          text: '模糊检测',
          value: 4,
        },
        {
          id: 5,
          text: '亮度检测',
          value: 5,
        },
        {
          id: 6,
          text: '静帧检测',
          value: 6,
        },
        {
          id: 7,
          text: '噪声检测',
          value: 7,
        },
        {
          id: 8,
          text: '闪烁检测',
          value: 8,
        },
        {
          id: 8,
          text: '滚动条纹检测',
          value: 9,
        },
      ],
      scroll: { y: window.innerHeight - 350,x:'100%' },
    };
  },
  computed: {},
  watch: {
    currentTaskId(nv, ov) {
      if (nv !== ov) {
        this.searchHandle();
      }
    },
  },
  methods: {
    getHt() {
      let maxHeight = document.documentElement.clientHeight;
      return {
        maxHeight: `${maxHeight - 140}px`,
      };
    },
    getIcon(text) {
      return text === 0 ? (
        <div class="text-green text-center">
          <a-icon type="check-circle" />
        </div>
      ) : (
        <div class="text-orange text-center">
          <a-icon type="warning" />
        </div>
      );
    },
    getDetail() {
      let data = {
        id: this.currentTaskId,
        params: {
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
          startTime: this.datePicker
            ? moment(this.datePicker[0]).format('YYYY-MM-DD HH:mm:ss')
            : '',
          endTime: this.datePicker
            ? moment(this.datePicker[1]).format('YYYY-MM-DD HH:mm:ss')
            : '',
          cameraName: this.cameraName,
          detectionStatus: this.currentStatus,
        },
      };
      this.loading = true;
      this.$api.collect.getDetectionCamera(data).then((res) => {
        if (res.code === CODE_OK) {
          /* this.cameraInfoList = res.data.map((item) => {
            item.detectionResult.split('').forEach((itemC, index) => {
              item[`test_0${index + 1}`] = itemC;
            });
            return item;
          }); */
          this.cameraInfoList = res.data.map((item) => {
            for (let key in item.detectionResultMap) {
              item[key] = item.detectionResultMap[key];
            }
            return item;
          });

          this.columns = this.tempColumns.slice().filter((item) => {
            if (this.cameraInfoList[0][item.key] !== null) {
              return true;
            }
          });

          console.log(this.cameraInfoList);
          this.paginationOpt.total = res.total;
          this.loading = false;
        } else {
          this.$message.error('获取详情出错！');
        }
      });
    },
    changeDataRange(datePicker) {
      this.datePicker = datePicker;
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 5;
      this.getDetail();
    },
    resetHandle() {
      this.currentStatus = '';
      this.datePicker = null;
      this.cameraName = '';
      this.searchHandle();
    },
    resetData() {
      this.currentStatus = '';
      this.datePicker = null;
      this.cameraName = '';
    },
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.wd120 {
  width: 30%;
  max-width: 140px;
}
.wd140 {
  width: 30%;
  max-width: 160px;
}
</style>
